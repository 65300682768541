.menuDelDia-container {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  animation: modal 2s 1s forwards;
  visibility: hidden;
  opacity: 0;
  z-index: 500;
  display: grid;
  place-items: center;
}
.menuDelDia-cerrar {
  position: absolute;
  width: 40px;
  height: 40px;
  top: calc(50% - 94px);
  right: calc(10% + 6px);
  background-color: var(--icon-enabled);
  border: none;
  border-radius: 20px;
  color: #fff;
  font-size: 20px;
}
.menuDelDia-modal {
  margin: auto;
  width: 80%;
  height: 200px;
  background-color: white;
  border-radius: 10px;
  display: grid;
  place-items: center;
}
.menuDelDia-contenido {
  position: relative;
}
.menuDelDia-contenido h2 {
  margin: 19px;
  color: var(--marron-logo);
}
.menuDelDia-contenido p {
  font-family: "Archivo", sans-serif;
  margin: 0 19px;
  font-size: 14px;
  color: var(--marron-logo);
}
@keyframes modal {
  100% {
    visibility: visible;
    opacity: 1;
  }
}

@media screen and (min-width: 600px) {
  .menuDelDia-modal {
    width: 40%;
  }
  .menuDelDia-cerrar {
    right: calc(30% + 6px);
  }
}