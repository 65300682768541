.nav-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: none;
  width: 100%;
  background-color: var(--gris-nav);
  height: 90px;
  padding: 0 15px;
  box-sizing: border-box;
  box-shadow: 0px 1px 11px 2px rgba(153, 153, 153, 1);
  -webkit-box-shadow: 0px 1px 11px 2px rgba(153, 153, 153, 1);
  -moz-box-shadow: 0px 1px 11px 2px rgba(153, 153, 153, 1);
}

.logo-container {
  position: relative;
  top: 15px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.titulo-container {
  display: flex;
}
.navTitulo {
  display: none;
}
.navDir {
  display: none;
}

.navButtons-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: calc(100vw - 100px);
  height: 90px;
  margin-left: 18px;
}

.navButton {
  position: relative;
  width: 30%;
  height: 100%;
}

.showsButton,
.cartaButton {
  width: 20%;
}
.acercaDeButton {
  width: 25%;
}

.nav-link {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.nav-icon i {
  font-size: 30px;
  text-decoration: none;
}
a {
  text-decoration: none;
}
.nav-icon {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 35%;
  width: 100%;
  height: 30%;
}
.icon-text {
  margin: 0;
  color: var(--icon-disabled);
  font-size: 12px;
  font-weight: 700;
  font-family: "Archivo", sans-serif;
}
.icon-text.faq {
  margin-top: 9px;
  text-align: center;
}
.nav-notch {
  position: absolute;
  display: none;
  width: 60%;
  height: 5px;
  bottom: 0;
  background-color: var(--icon-enabled);
}
.nav-link i {
  color: var(--icon-disabled);
}
.nav-link.active i {
  color: var(--icon-enabled);
}
.nav-link.active p {
  color: var(--icon-enabled);
}
.nav-link.active .nav-notch {
  display: block;
}

@media screen and (min-width: 600px) {
  .titulo-container {
    display: flex;
    align-items: center;
    justify-content: start;
  }
  .tituloYDirContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .navTitulo {
    margin: 0 0 0 15px;
    display: block;
    white-space: nowrap;
    font-size: 30px;
    line-height: 30px;
  }
  .navDir {
    display: block;
    margin: 0 0 0 15px;
    white-space: nowrap;
    font-size: 16px;
    line-height: 15px;
  }

  .logo-container {
    top: 0;
  }
  .navButtons-container {
    width: 40%;
  }
}
