.reservas-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  width: 100%;
  height: 80px;
  background-color: var(--gris-nav);
  padding: 0;
  box-sizing: border-box;
  bottom: 0;
}

.reservasText-container {
  width: 450px;
  height: auto;
  display: flex;
  justify-content: center;
}
.reservasText-reservas-container {
  text-align: center;
  line-height: 30px;
  width: 80px;
}
.reservasText-reservas-container p,
.text-telefono,
.text-email {
  margin: 0;
  font-family: "Archivo", sans-serif;
  color: var(--icon-enabled);
  font-size: 13px;
  font-weight: 700;
}
.barrita {
  height: auto;
  width: 2px;
  background-color: var(--icon-enabled);
}
.icon-telefono,
.icon-mail,
.text-email,
.text-telefono {
  color: var(--icon-enabled);
  display: inline;
}
.text-email,
.text-telefono {
  font-size: 13px;
}
.icon-telefono,
.icon-mail {
  font-size: 13px;
  margin: 0 3px 0 7px;
}
.reservasText-mail-container,
.reservasText-telefono-container {
  display: flex;
  align-items: center;
}
.logoFooter {
  position: relative;
  float: right;
}
.logoFooter img {
  margin: 0 10px;
  height: 30px;
}
.logosFooter-container {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
}
.logoBamusica-container img {
  margin-top: 5px;
  height: 24px;
}

@media screen and (min-width: 600px) {
  .reservasText-reservas-container p,
  .text-telefono,
  .text-email {
    font-size: 20px;
  }
  .reservas-container {
    padding: 25px 22px 25px 13px;
    flex-direction: row;
  }
  .text-email,
  .text-telefono {
    font-size: 16px;
  }
  .icon-telefono,
  .icon-mail {
    font-size: 16px;
  }
  .reservasText-reservas-container {
    width: 120px;
  }
}
